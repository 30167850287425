.my-appointment-drawer .transaction-details {
  height: auto;
}

.transaction-details {
  border: 1px solid #E5EBF1;
  border-radius: 7px;
  background-color: #FFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  .transaction-details-header {
    color: #252525;
    font-size: 1.5rem;
  }

  .transaction-details-table {
    padding: 0px 4px;
    table-layout: fixed;

    td {
      padding-bottom: 10px;
    }

    tr td:last-child {
      text-align: right;
    }

    .summary-row {
      font-weight: 800;
    }

    .collected-row {
      background-color: rgba(150, 150, 255, .25);
    }

    td.money-value {
      text-align: center;
    }

    .sub-check {
      padding-left: 24px;
    }

    button.collect-payment-btn {
      color: blue;
    }
  }

  .transaction-details-body {
    height: 100%;
    line-height: 2;
    font-size: 1rem;
  }
}

.MuiCheckbox-root svg {
  width: 1.5em;
  height: 1.5em;
}