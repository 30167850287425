.plan-view {
  display: contents;
}

.plan-container {
  border-radius: 28px;
  background: #ffffff;
  box-shadow: 8px 8px 16px #949494, -8px -8px 16px #ffffff;
}

.fc .fc-scroller-liquid-absolute {
  position: relative;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent !important;
  padding: 3px;
}

.fc-timegrid-event-harness {
  border: none !important;
}

.fc .fc-timegrid-col.fc-day-today .fc-timegrid-col-frame {
  border: 1px solid rgba(103, 223, 187, 1);
  background-color: rgba(103, 223, 187, 0.18);
  border-radius: 7px;
  // background-color: transparent !important;
}

.fc-event-main .text-wrapper {
  //  background-color: rgba(103, 223, 187, 0.18) !important;
  // color: #000 !important;
}

@media (min-width: 768px) {
  .fc-event-main b,
  .fc-event-main i {
    font-size: 1rem !important;
    font-style: inherit !important;
  }
}

@media (max-width: 480px) {
  .plan-calendar-wrapper .fc th span {
    font-size: 8px;
    word-break: break-word;
  }

  .plan-calendar-wrapper .fc-col-header-cell-cushion {
    font-size: 11px;
  }
}

.plan-calendar-wrapper::-webkit-scrollbar {
  display: none;
}

@media (max-width: 880px) {
  .plan-view {
    margin-top: 65px;
  }
}

.plan-calendar-wrapper {
  height: calc(100vh - 270px);
  overflow-y: scroll;
}

.fc-timegrid-event-harness {
  border: 1px solid rgba(103, 223, 187, 1);
}

.money-icon,
.notes-icon {
  border-radius: 200px;
  background-color: white;
  color: var(--primary-color);
  width: inherit;
  height: inherit;
  position: absolute;
}

.notes-icon {
  padding: 2px;
  background-color: #526eff;
}

@media (min-width: 320px) {
  .fc-event-main {
    .icon {
      width: 14px;
      height: 14px;
      top: -8px;
      right: -4px;
    }

    .flag-wrapper {
      width: 16px;
      height: 16px;
      top: -10px;
      left: -4px;
    }

    .trash-icon {
      width: 12px;
      height: 12px;
      bottom: 2px;
      right: 2px;
    }

    b,
    i {
      font-size: 10px;
    }

    .money-icon {
      width: 16px;
      height: 16px;
      top: -8px;
      left: -4px;
    }

    .notes-icon {
      width: 14px;
      height: 14px;
      bottom: -8px;
      right: -4px;
    }
  }
}

@media (min-width: 768px) {
  .fc-event-main {
    .icon {
      width: 20px;
      height: 20px;
      top: -10px;
      right: -6px;
    }

    .flag-wrapper {
      width: 18px;
      height: 18px;
      top: -12px;
      left: -6px;
    }

    .flag-icon {
      color: white;
      width: inherit;
      height: inherit;
      padding: 2px;
      position: absolute;
      top: -1px;
      left: 0px;
    }

    .trash-icon {
      width: 16px;
      height: 16px;
      bottom: 2px;
      right: 2px;
    }

    b,
    i {
      font-size: 14px;
    }

    .money-icon {
      width: 20px;
      height: 20px;
      top: -10px;
      left: -6px;
    }

    .notes-icon {
      width: 22px;
      height: 22px;
      bottom: -10px;
      right: -6px;
    }
  }
}

.fc-event-main {
  .flag-wrapper {
    display: inline-block;
    animation: rotation 2s infinite linear;
    position: absolute;
    padding: 3px;
    background-color: var(--primary-color);
    border-radius: 200px;
    transform-origin: center bottom;
  }

  @keyframes rotation {
    25% {
      transform: rotate(25deg);
    }

    50% {
      transform: rotate(0deg);
    }

    75% {
      transform: rotate(-25deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .icon {
    position: absolute;
    padding: 1px;
    background-color: white;
    border-radius: 200px;
  }

  .trash-icon {
    position: absolute;
    padding: 1px;
    cursor: pointer;
    pointer-events: all;
  }

  .text-wrapper {
    max-height: 100%;
    overflow: hidden;
  }
}

.calendar-event-now-serving {
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 180, 0, 0.6);
    }

    90% {
      -webkit-box-shadow: 0 0 0 20px rgba(0, 180, 0, 0);
    }

    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 180, 0, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 180, 0, 0.6);
      box-shadow: 0 0 0 0 rgba(0, 180, 0, 0.4);
    }

    90% {
      -moz-box-shadow: 0 0 0 20px rgba(0, 180, 0, 0);
      box-shadow: 0 0 0 20px rgba(0, 180, 0, 0);
    }

    100% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 180, 0, 0);
      box-shadow: 0 0 0 0 rgba(0, 180, 0, 0);
    }
  }
}

.calendar-event-now-serving {
  border-top: 6px solid green !important;
  box-shadow: 0 0 0 rgb(0, 0, 0);
  animation: pulse 1.2s infinite;
}

.calendar-event-cancel {
  .text-wrapper {
    b,
    i {
      text-decoration: line-through;
    }
  }
}
