.profileContainer
    display: flex
    .profileHeader
        position: fixed
        width: 100%
        display: flex
        justify-content: space-between
        height: 10vh
        align-items: center
        .logOut
            margin-right: 5%
            cursor: pointer
            transition: .1s
            span
                margin-right: 10px
                cursor: pointer
        &:hover
            color: #63BBF2

.sideTab
    padding-left: 220px
    padding-top: 50px
.mainBorderStyle
    border: 1px solid #d3d3d3
    padding: 1rem
    padding-right: 4rem
.sidebarContent
    padding: 0.5rem
    border: 1px solid #d3d3d3
    border-right: 0px
    display: table-cell
    &:last-child
        border-right: 1px solid #d3d3d3
.action-cell
    width: 100px
.bookingTable
    background-color: #EBEBEB
    width: 100%
    tr
        border-top: 1px solid rgba(0,0,0,.15)
.tableButton
    background-color: #FF3600
    color: #FFFFFF
    border: none
    border-top-right-radius: 25px
    border-bottom-right-radius: 25px
    padding: 0.5rem
    margin-top: 20px
.emailButton
    background-color: #FF3600
    color: #FFFFFF
    border: none
    margin-bottom: 20px
    margin-right: 20px
    padding: 0.5rem
    margin-top: 20px
.lineBox
    margin: 0px
    padding: 0px
    width: 100%
.sideBar
    border: 1px solid #d3d3d3
    height: 515px
.mainBookingBorder
    border: 1px solid #d3d3d3
    padding-bottom: 40px

.packagesContainer
    width: 83vw
    margin: auto
    top: 12vh
    position: relative
    text-align: center

.newProfileLogo
    height: 65%
    position: relative
    left: 8%
