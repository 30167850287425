.CampaignUpsertPopup .CustomerTableContainer td,
.CampaignUpsertPopup .CustomerTableContainer th {
  vertical-align: middle !important;
}

.CampaignUpsertPopup .base-modal-title {
  flex-wrap: nowrap;
  align-items: center;
}

.CampaignUpsertPopup .title {
  width: stretch;
  margin-bottom: 0;
}
.base-modal table.table thead th span {
  padding-left: 0.4rem;
}
