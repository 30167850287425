.form_filler
    margin: 0
    padding: 0

.form-fill-top-bar
    top: 0
    width: 100%
    height: 15vh
    display: flex
    align-items: center
    color: #252525

.form-fill-header
    text-align: center
    width: 100%
.submitButton
    margin: 1em auto 4em auto
    display: flex
    justify-content: flex-end
    .formSubmitButt
        background-color: blue
.form-generator
    .Mui-disabled option,
    .Mui-disabled 
        color: black !important