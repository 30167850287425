@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap')

.sideDrawer
    width: 9%
    font-family: 'Titillium Web', sans-serif !important

    .MuiDrawer-paper
        width: 6% !important

    .selected
        background: #63BBF2
    .drawerList
        height: 100vh
        display: flex
        flex-direction: column
        justify-content: center
        .drawerListItems
            display: flex
            flex-direction: column
            padding: 15px
            &:hover
                background: #63BBF2

                align-self: center
            .MuiListItemIcon-root
                min-width: auto !important
                align-self: center
                font-size: 200px
                color: black !important
                .MuiSvgIcon-root
                    fill: currentColor
                    width: 1.6em
                    height: 1.6em
                    display: inline-block
                    font-size: 1.5rem
                    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
                    flex-shrink: 0
                    user-select: none

            .MuiTypography-body1
                text-align: center
                padding: 5px
                font-family: 'Titillium Web', sans-serif
