.IntakeCustomer .count {
  background-color: red;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  margin: 2px 0 0 2px;
}