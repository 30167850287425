.CreateSpeedDial .CreateSpeedDialChild {
  white-space: nowrap;
  cursor: pointer;
  padding: 8px;
  background-color: #3f51b5;
  color: white;
  margin: 4px 2px;
  border-radius: 6px;
}

.CreateSpeedDial .CreateSpeedDialChild:hover {
  background-color: #303f9f;
}

.CreateSpeedDial .MuiSpeedDial-actions {
  padding-bottom: 38px!important;
}