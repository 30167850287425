.program-transaction-details {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 15px;
  background-color: #EEEEEE66;

  .transaction-details-header {
    border-radius: 7px 7px 0px 0px;
    margin-top: 15px;
    color: #252525;
  }

  .transaction-details-table {
    padding: 0px 4px;
    table-layout: fixed;

    td {
      padding-bottom: 10px;
    }

    tr td:last-child {
      text-align: right;
    }

    .summary-row {
      font-weight: 800;
    }

    .collected-row {
      background-color: rgba(150, 150, 255, .25);
    }

    td.money-value {
      text-align: center;
    }

    .sub-check {
      padding-left: 24px;
    }
  }

  .transaction-details-body {
    height: 100%;
    line-height: 1.6;
    font-size: 1rem;

    .MuiCheckbox-root .MuiSvgIcon-root {
      height: 30px;
      width: 30px;
    }
  }
}