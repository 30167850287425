.CalendarView {
  .CalendarContainer .fc-view-harness {
    max-height: calc(100% - 73px);
  }

  .CalendarContainer .fc-timegrid-event-harness {
    margin-right: 2px;
  }

  .TopBar {
    margin-bottom: 2rem;

    .program-select {
      width: 120px;
    }

    .arrow-btn {
      background-color: #f6943b;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 34px;
      height: 34px;
    }
  }
}

.base-modal .table td {
  padding: 0.5rem 0;
}

.base-modal table.table thead th,
.base-modal table.table thead th span {
  padding: 0.5rem 0;
}

@media screen and (min-width: 0px) and (max-width: 426px) {
  .base-modal table.table thead th,
  .base-modal table.table thead th span,
  .base-modal .table td {
    padding: 0.5rem 1rem;
  }
}
