
.ScheduleTemplateBuilder {
  .TopBar {
    padding: 4px 8px;
    align-items: center;
    background-color: #002147;
    color: white;
    .MuiInput-underline:before {
      border-bottom: 1px solid rgba(255, 255, 255, .87) !important;
    }
    .program-select {
      width: 120px;
    }
    .arrow-btn {
      color: white;
      margin: 4px;
    }
    .MuiInput-root, .MuiTextField-root {
      .MuiInput-input, .MuiSelect-nativeInput, .MuiSelect-icon, .MuiInputLabel-root {
        color: white !important;
      }
    }
  }
  .CalendarContainer .fc-view-harness {
    max-height: calc(100% - 73px);
  }
  .CalendarContainer .fc-timegrid-event-harness {
    margin-right: 2px;
  }
}