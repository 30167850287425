.CreateAppointment .adjusted-time-modifier {
  color: white !important;
  background-color: #f6943b !important;
  padding: 0;
  font-weight: bold;
  font-size: 16px;
  min-width: 34px;
  height: 32px;
  border-radius: 7px;
}

.primaryButtonLabel {
  text-transform: none;
  font-size: 12px;
}

.CreateAppointment .adjusted-time-field {
  margin-left: 8px;
  margin-right: 8px;
  width: 45px;
  height: 34px;
}

.CreateAppointment .adjusted-time-field input {
  text-align: center;
}

.CreateAppointment .TimeChip {
  width: 100%;
  white-space: nowrap;
}

.CreateAppointment .TimeList {
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  max-height: 330px;
  overflow-y: auto;
}

.account-balance {
  font-weight: bold;
  background: #f4f6f8 0% 0% no-repeat padding-box;
  color: #252525;
  padding: 8px;
  margin: 8px 0px;
  align-items: center;
  border-radius: 7px;
}

@media only screen and (max-width: 480px) {
  .CreateAppointment .TimeList {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }

  .CreateAppointment .CreateAppointmentContainer {
    padding: 0 !important;
  }
}