.profileSection
    // background: red
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .profileSectionContent
        border: 1px solid black
        border-radius: 10px
        padding: 20px
        width: 70%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 20px
        .MuiInputBase-root
            border-color: black !important
        .MuiButtonBase-root
            background: #63BBF2

    .profileSectionContent :first-child
        grid-column: 1 / -1
    .profileSectionContent :last-child
        grid-column: 1 / -1
    .profileTitle
        font-size: 1.7em
        font-weight: bold
