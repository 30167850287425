.plan-calendar {
  .notification {
    background: red;
    border-radius: 50%;
    position: absolute;
    right: -0.7em;
    top: -1.4em;
    width: 1em;
    height: 1em;
  }

  .indicator {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-content: center;

    b {
      margin: auto;
    }
  }
}

.unavailability-event {
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.4) 6px,
    rgba(255, 255, 255, 0.4) 6px,
    rgba(255, 255, 255, 0.6) 10px
  );
}

.fc-license-message {
  display: none;
}
