.CustomerSubscriptionReportFilters .react-datepicker-wrapper {
  width: 100%;
}

.reportBtn{
  border: 1px solid #E5EBF1;
  background-color: #F6F8FA;
  border-radius: 7px;
  width: 78px;
  height: 34px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #252525;  
}

.reportBtn:focus{
  outline: none;
}

.reportBtn.active{
  background-color: #B3D7ED;
  border: 1px solid #B3D7ED;
}