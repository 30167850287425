.bookingHistory
    width: 90%
    height: 100%

    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin-top: 10vh
    .tableHead
        font-size: 1.2em
        font-weight: bold
    h2
        margin-bottom: 35px

    .MuiTableContainer-root
        width: 90%
.chatBoxProfile
    background: red
