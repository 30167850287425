.employee-request-funds {
  .balance-card {
    flex: 1;
    margin: 0px 8px;
    .balance-card-body {
      padding: 8px;
      background-color: #DDD;
      .balance-card-body-title {
        font-size: 16px;
        font-weight: 800;
      }
      .balance-card-body-subtitle {
        font-size: 14px;
      }
    }
  }
}