.expense-upsert {
  .autocomplete-readonly {
    .MuiAutocomplete-paper, .MuiChip-deleteIcon {
      display: none !important;
    }
    .Mui-disabled .MuiChip-root {
      color: rgba(0,0,0,0.87);
    }
    .Mui-disabled  {
      color: rgba(0,0,0,0.54);
      opacity: 1;
    }
    .MuiInput-underline:before  {
      border-bottom-style: solid;
    }
  }
}