.content-container {
  padding: calc(0.5rem);
  padding-bottom: 40px;
  padding-top: calc(0.5rem);
  height: 100%;
}

.mobile .content-container {
  padding: 0;
  padding-bottom: calc(40px + var(--appbar-height)) !important;
}

.full-height {
  height: 100%;
}

.booking-search-bar-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 9px;
}

.category-pill,
.primary-header {
  background-color: var(--primary-color);
  color: white;
}

/* .table thead {
  background-color: var(--primary-color);
} */

.table thead th,
.table thead th span {
  color: white !important;
}

.table thead th {
  border-bottom: 1px solid #e5ebf1;
}

.table td,
.table th {
  border-top: 1px solid #e5ebf1;
}

.table tbody tr {
  background-color: #fff;
}

.view-icon {
  border-radius: 7px;
  width: 28px !important;
  height: 28px;
  padding: 5px;
  background-color: #f6943b;
  margin: 0 4px;
  cursor: pointer;
}

.swal2-container {
  z-index: 9999 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.routes-main-content {
  min-height: calc(100vh - var(--appbar-height) - 40px);
  max-height: calc(100vh - var(--appbar-height) - 40px);
  overflow-y: auto;
}

.mobile .routes-main-content {
  min-height: calc(100vh - var(--appbar-height));
  max-height: calc(100vh - var(--appbar-height));
}

.mobile .routes-main-content.routes-announcements {
  min-height: calc(100vh - var(--appbar-height) - 20px);
  max-height: calc(100vh - var(--appbar-height) - 20px);
  margin-top: 20px;
}

.primary-button {
  background-color: #ff3600 !important;
  color: white !important;
}

.MuiPopover-root {
  z-index: 1306 !important;
}

.employee-service-card-wrapper {
  min-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1302;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}

.MuiFormControl-root {
  width: 100%;
}

.light-border {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.light-border-t {
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.light-border-x {
  border-left: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.light-border-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.MuiAutocomplete-popper {
  z-index: 1500 !important;
  animation: none !important;
  transition: none !important;
}

.half-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 576px) {
  .half-container {
    padding: 0;
  }
}

@media (min-width: 576px) {
  .half-container {
    max-width: 360px;
  }
}

@media (min-width: 768px) {
  .half-container {
    max-width: 480px;
  }
}

@media (min-width: 992px) {
  .half-container {
    max-width: 576px;
  }
}

@media (min-width: 1200px) {
  .half-container {
    max-width: 576px;
  }

  .small-container {
    max-width: 480px;
  }
}

.module-selector {
  border-bottom: none;
}

.module-selector-popover {
  transform-origin: 0px !important;
  left: 0px !important;
}

.table.table-bordered tr {
  border-width: 0 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.3);
}

.menu {
  position: absolute;
  width: 100%;
  max-width: 100%;
  padding-right: 0px !important;
  z-index: 100;
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.TableInput .MuiInputBase-input {
  padding: 4px;
}

.saloon-footer-text {
  color: #252525;
  margin-bottom: 0px;
  text-align: center;
  font: normal normal normal 12px/15px Montserrat;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.dialStyles button {
  min-height: 57px;
}

.dialStyles button:hover {
  background-color: #54b3ee;
}

.dialStyles button span {
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: #252525;
  font-weight: 700;
}

.removePadding {
  padding: 0;
}

@media screen and (min-width: 800px) {
  .dialStyles button {
    height: 100px;
    width: 24px;
    min-height: 100px;
    background-color: #b3d7ed;
    border-radius: 7px 0px 0px 7px;
  }
}

@media screen and (max-width: 426px) {
  .saloon-footer-text {
    font-size: 0.625rem;
  }
}

@media screen and (max-width: 480px) {
  .mobile-only {
    display: inline;
  }

  .desktop-only {
    display: none;
  }
}

@media screen and (min-width: 481px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: inline;
  }
}

.form-button-query {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  .form-button-query {
    justify-content: start;
  }

  .fc .fc-col-header-cell-cushion {
    display: block;
    padding: 2px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .fc th,
  .fc td {
    vertical-align: middle;
  }
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.negativeMargins {
  margin-left: -15px;
  margin-right: -15px;
}

.text-no-wrap {
  white-space: nowrap;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-5 {
  gap: 3rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.textAlign input {
  text-align: center;
}

.sun-editor .se-toolbar {
  z-index: 3 !important;
}

.fc-timegrid-event .fc-event-time,
.fc-v-event .fc-event-title,
.fc .fc-col-header-cell-cushion {
  color: #252525;
}

.active-btn {
  background-color: #b3d7ed !important;
  border: 1px solid #b3d7ed !important;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 480px) {
  .site-form input,
  .w-35,
  .mobile-w-100,
  .w-50 {
    width: 100% !important;
  }

  .validate-flex {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start !important;
  }

  .validate-flex button {
    margin: 0 !important;
  }

  .validate-input {
    width: 100% !important;
  }

  .font-weight-bold {
    margin-bottom: 5px;
  }
  .appointment-details-employee-name,
  .appointment-details-employee-other {
    width: max-content;
  }
  .mobile-max-content {
    width: max-content !important;
  }
}
