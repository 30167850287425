.DateAndTime .react-calendar {
  width: 100% !important;
}

.DateAndTime .react-calendar__tile,
.DateAndTime .react-calendar__navigation {
  height: 48px;
}


div.custom-calendar {
  width: 100%;
  box-shadow: unset;
  border-radius: 0;
}

.custom-calendar .rmdp-calendar,
.custom-calendar .rmdp-day-picker div {
  flex-grow: 1;
}

.custom-calendar .rmdp-day span {
  border-radius: 0;
}

.custom-calendar .rmdp-calendar .rmdp-header,
.custom-calendar .rmdp-calendar .rmdp-week-day,
.custom-calendar .rmdp-calendar .rmdp-day span {
  font-size: inherit;
}