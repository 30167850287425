@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
body {
  font-family: "Montserrat", sans-serif !important;
  transition: 0.3s !important;
  --white-color: #ffffff;
  --black-color: #252525;
  --warning-color: #f6943b;
  --primary-color: #b3d7ed;
  --lightblue1-color: #f6f8fa;
  --lightblue2-color: #f4f6f8;
  --error-color: #f44336;
  --appbar-height: 56px;
}

.fas,
.fa {
  font-family: "Font Awesome 5 Free" !important;
}
.btn-postitive {
  color: #fff !important;
  background-color: green !important;
  border-color: green !important;
  text-transform: uppercase;
}
